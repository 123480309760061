import React from "react";

import Fire from "../../../assets/images/icons/fire.png";

import "./LoadBar.scss";

const LoadBar = ({ streak = null, progress }) => {
  //console.log(props);
  let fire = null;
  if (streak > 2) {
    fire = (
      <img
        className="LoadBar__fire animated pulse infinite "
        src={Fire}
        alt=""
      />
    );
  }
  let progress_color = "";

  if (progress >= 20) {
    progress_color = "orange";
  }
  if (progress >= 50) {
    progress_color = "yellow";
  }
  if (progress >= 80) {
    progress_color = "palegreen";
  }
  if (progress === 100) {
    progress_color = "green";
  }

  console.log("color progress", progress_color, progress);
  return (
    <div className="LoadBar">
      <div className="LoadBar__bar">
        <div
          style={{ width: `${progress}%` }}
          className={`LoadBar__progress ${progress_color}`}
        ></div>
      </div>

      {streak && (
        <div className="LoadBar__streak">
          <span>Streak</span>
          <div className="LoadBar__streak-container">
            {streak}
            {fire}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadBar;
