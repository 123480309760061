import axios from "axios";

export async function updateProfilePictureService(user, photo) {
  try {
    let formData = new FormData();
    formData.append("user", user);
    formData.append("photo", photo);
    await axios.put(`/users/profile-pic`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function getRankingStreaksService() {
  try {
    const response = await axios.get(`/users/streaks`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export async function loginService(data) {
  try {
    const response = await axios.post(`/logUser`, data);
    return response;
  } catch (error) {
    console.log("error", error?.response);
    return error.response;
  }
}

export async function getAllUsersService() {
  try {
    const response = await axios.get(`/users`);
    return response.data;
  } catch (error) {
    console.log("error", error?.response);
    return error.response;
  }
}

