import React, { useEffect, useState } from "react";
import LoadBar from "../../../../components/UI/LoadBar/LoadBar";
import Doty from "../../../../components/UI/Doty/Doty";
import { getDailyProgressService } from "../../../../services/progress.service";
import { Card, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
const user = JSON.parse(localStorage.getItem("user"));

export default function DailyProgress() {
  const [progress, setProgress] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const getPRogress = async () => {
      const progress_res = await getDailyProgressService(user.id);
      setProgress(progress_res.progress);
    };
    getPRogress();
  }, []);
  const initial_pose = "11";
  let pose = initial_pose;
  let message = "Let's get this done!";
  console.log(progress);

  if (progress === 100 && pose === initial_pose) {
    pose = "17";
    message = "Well done!";
  }

  if (progress >= 80 && pose === initial_pose) {
    pose = "06";
    message = "Almost!";
  }

  if (progress >= 50 && pose === initial_pose) {
    pose = "06";
    message = "Keep going!";
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: isMobile ? "50rem" : "30rem",
        padding: "2rem",
        height: "100%",
        overflow: "hidden auto",
      }}
    >
      <Typography variant={isMobile ? "h3" : "h5"}>
        My progress today
      </Typography>
      <Typography variant={isMobile ? "h4" : "h6"}>{progress}%</Typography>
      <LoadBar progress={progress} />
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <Doty pose={pose} size={isMobile ? "tiny" : "mini"} />
        <Typography variant={isMobile ? "h4" : "h6"} gutterBottom>
          {message}
        </Typography>
      </Stack>
    </Card>
  );
}
