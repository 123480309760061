import axios from "axios";

export async function getDailyProgressService(user_id) {
  try {
    const readings = await axios.get("/progress/" + user_id);

    return readings.data;
  } catch (error) {
    return false;
  }
}

export async function getRankingStudentsService() {
  try {
    const top_students = await axios.get("/progress/top-students");

    return top_students.data;
  } catch (error) {
    console.log(error);
  }
}
